/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type ReportingItemProps = {
  editBtn: ButtonIconProps,
  deleteBtn: ButtonIconProps,
  name: TypographyProps & {},
  viewBtn: ButtonProps & {},
  lastView: TypographyProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  ahfqjr: BoxProps,
  bakfuh: ButtonIconProps,
  zvnrqz: IconProps,
  nqjfab: ButtonIconProps,
  lcbgzn: IconProps,
  cvlykj: TypographyProps,
  aavutn: ButtonProps,
  zaxjlk: TypographyProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const ReportingItem: React.FC<ReportingItemProps> = (props) => {
  const { editBtn, deleteBtn, name, viewBtn, lastView } = props;
  const {
    ROOT,
    ahfqjr,
    bakfuh,
    zvnrqz,
    nqjfab,
    lcbgzn,
    cvlykj,
    aavutn,
    zaxjlk,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <Box {...ahfqjr}>
        <Typography {...cvlykj} {...name} />
        <Typography {...zaxjlk} {...lastView} />
      </Box>
      <Button {...aavutn} {...viewBtn} />
      <ButtonIcon {...nqjfab} Icon={<Icon {...lcbgzn} />} {...deleteBtn} />
      <ButtonIcon {...bakfuh} Icon={<Icon {...zvnrqz} />} {...editBtn} />
    </Container>
  );
};

export default ReportingItem;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: {
      padding: "12px",
      boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
      gap: "12px",
      borderRadius: "22px",
      marginBottom: "12px",
      display: "flex",
      alignItems: "center",
    },
  },
  ahfqjr: { tag: "div", css: { flexGrow: "1", paddingLeft: "12px" } },
  bakfuh: {
    type: "button",
    disabled: false,
    disableFocusRipple: false,
    disableRipple: false,
    size: "medium",
    color: "primary",
  },
  zvnrqz: {
    color: "inherit",
    icon: { name: "HiOutlineCog6Tooth", library: "hi2" },
    fontSize: "inherit",
    hidden: false,
  },
  nqjfab: {
    type: "button",
    disabled: false,
    disableFocusRipple: false,
    disableRipple: false,
    size: "medium",
    color: "secondary",
  },
  lcbgzn: {
    color: "inherit",
    icon: { name: "TfiTrash", library: "tfi" },
    fontSize: "inherit",
    hidden: false,
  },
  cvlykj: {
    variant: "h4",
    text: "Customer 1",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "18px" },
  },
  aavutn: {
    text: "Consulter",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
  zaxjlk: {
    variant: "overline",
    text: "Consulté il y a 10 min",
    gutterBottom: false,
    noWrap: false,
  },
};

/** DEFAULT PROPS End */
