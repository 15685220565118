import React from "react";
import { Breadcrumbs, Link, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useNavigate } from "@repleek/mui";

type BreadCrumbDefinition = {
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  href?: string;
};

export type BreadCrumbProps = {
  breadCrumbs?: BreadCrumbDefinition[];
};

const BreadCrumb: React.FC<BreadCrumbProps> = (props) => {
  const { breadCrumbs = [] } = props;
  const { navigate } = useNavigate();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadCrumbs.map(({ label, icon: Icon, href }) =>
        href ? (
          <Link
            key={label}
            underline="hover"
            onClick={() => navigate?.(href)}
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit">
            {Icon && <Icon sx={{ mr: 0.5 }} fontSize="inherit" />}
            {label}
          </Link>
        ) : (
          <Typography
            key={label}
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary">
            {Icon && <Icon sx={{ mr: 0.5 }} fontSize="inherit" />}
            {label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
