import { ReactElement } from "react";
import Home from "./Home";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import NewAccount from "./NewAccount";
import Account from "./Account";
import PageNotFound from "./PageNotFound";
import Oauth from "./Oauth";
import NewReport from "./NewReport";
import Reporting from "./Reporting";
import Users from "./Users";

const pages: { path: string; page: ReactElement }[] = [
  {
    path: "*",
    page: <PageNotFound />,
  },
  {
    path: "/",
    page: <Home />,
  },
  {
    path: "/login",
    page: <Login />,
  },
  {
    path: "/reset-password",
    page: <ResetPassword />,
  },
  {
    path: "/new-account",
    page: <NewAccount />,
  },
  {
    path: "/users",
    page: <Users />,
  },
  {
    path: "/account/:id",
    page: <Account />,
  },
  {
    path: "/edit-account/:id",
    page: <NewAccount />,
  },
  {
    path: "/oauth/:provider",
    page: <Oauth />,
  },
  {
    path: "/:accountId/new-reporting",
    page: <NewReport />,
  },
  {
    path: "/:accountId/reporting/:id",
    page: <Reporting />,
  },
  {
    path: "/:accountId/edit-reporting/:id",
    page: <NewReport />,
  },
];

export const navigations = {
  admin: [
    {
      label: "Comptes",
      href: "/",
    },
    {
      label: "Utilisateurs",
      href: "/users",
    },
  ],
  customer: [],
  default: [],
};

export default pages;
