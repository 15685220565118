/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  Chip,
  ChipProps,
  Container,
  ContainerProps,
  Divider,
  DividerProps,
  Form,
  FormProps,
  Icon,
  IconProps,
  Paper,
  PaperProps,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type AccountFormProps = {
  form: FormProps,
  fields: BoxProps,
  title: TypographyProps & {},
  email: TextFieldProps,
  back: ButtonProps & {},
  type: SelectFieldProps,
  loader: BoxProps,
};

type DefaultProps = {
  ROOT: FormProps,
  tjjbjt: TextFieldProps,
  cytndq: ButtonProps,
  uheawu: PaperProps,
  nrmlgm: BoxProps,
  dbtfnq: TypographyProps,
  xihrtt: ContainerProps,
  xfyxtu: BoxProps,
  unlkmt: TextFieldProps,
  yqrvzz: CheckboxProps,
  appnth: DividerProps,
  fpnaco: ChipProps,
  mmtxhu: AvatarProps,
  fygemc: IconProps,
  pigijy: CheckboxProps,
  oqrbip: CheckboxProps,
  xkxhry: CheckboxProps,
  jckckj: CheckboxProps,
  axsmgg: BoxProps,
  clvqsl: ButtonProps,
  pthrch: SelectFieldProps,
  hbealt: BoxProps,
  guwxgg: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const AccountForm: React.FC<AccountFormProps> = (props) => {
  const { form, fields, title, email, back, type, loader } = props;
  const {
    ROOT,
    tjjbjt,
    cytndq,
    uheawu,
    nrmlgm,
    dbtfnq,
    xihrtt,
    xfyxtu,
    unlkmt,
    yqrvzz,
    appnth,
    fpnaco,
    mmtxhu,
    fygemc,
    pigijy,
    oqrbip,
    xkxhry,
    jckckj,
    axsmgg,
    clvqsl,
    pthrch,
    hbealt,
    guwxgg,
  } = defaultProps;

  return (
    <Form {...ROOT} {...form}>
      <Container {...xihrtt}>
        <Paper {...uheawu}>
          <Typography {...dbtfnq} {...title} />
          <Box {...guwxgg} {...loader} />
          <Box {...nrmlgm} {...fields}>
            <TextField {...tjjbjt} />
            <Box {...hbealt}>
              <TextField {...unlkmt} {...email} />
              <SelectField {...pthrch} {...type} />
            </Box>
            <Checkbox {...yqrvzz} />
            <Divider
              {...appnth}
              Chip={
                <Chip
                  {...fpnaco}
                  Avatar={<Avatar {...mmtxhu} />}
                  Icon={<Icon {...fygemc} />}
                />
              }
            />
            <Box {...axsmgg}>
              <Checkbox {...pigijy} />
              <Checkbox {...oqrbip} />
              <Checkbox {...jckckj} />
              <Checkbox {...xkxhry} />
            </Box>
            <Box {...xfyxtu}>
              <Button {...clvqsl} {...back} />
              <Button {...cytndq} />
            </Box>
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default AccountForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { mode: "onSubmit", css: { fontSize: "12px" } },
  tjjbjt: {
    inputName: "name",
    label: "Nom du compte",
    variant: "outlined",
    color: "secondary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { marginBottom: "16px" },
  },
  cytndq: {
    text: "Enregistrer",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
  },
  uheawu: {
    square: false,
    variant: "outlined",
    elevation: 0,
    css: {
      padding: "42px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "26px",
    },
  },
  nrmlgm: { tag: "div", css: { marginBottom: "16px" } },
  dbtfnq: {
    variant: "h1",
    text: "Nouveau compte",
    gutterBottom: false,
    noWrap: false,
    css: { marginBottom: "22px", textTransform: "uppercase", fontSize: "36px" },
  },
  xihrtt: { maxWidth: "sm", disableGutters: false, fixed: false },
  xfyxtu: {
    tag: "div",
    css: {
      marginTop: "16px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  unlkmt: {
    inputName: "email",
    label: "E-mail",
    variant: "outlined",
    color: "secondary",
    type: "email",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
  },
  yqrvzz: {
    inputName: "notification",
    label: "Activer la notification par email",
    color: "primary",
    isChecked: false,
    disabled: false,
    disableTypography: false,
    "aria-label": "Input Base Mui",
    size: "medium",
  },
  appnth: {
    label: "Utilisateur",
    chip: true,
    flexItem: false,
    light: false,
    orientation: "horizontal",
    textAlign: "left",
    variant: "fullWidth",
  },
  fpnaco: {
    label: "Réseaux sociaux",
    avatar: false,
    icon: false,
    clickable: false,
    color: "secondary",
    variant: "filled",
    hidden: false,
    css: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "uppercase",
    },
  },
  mmtxhu: {
    variant: "circular",
    component: "div",
    className: "MuiChip-avatar MuiChip-avatarMedium MuiChip-avatarColorDefault",
  },
  fygemc: {
    color: "inherit",
    icon: { library: "fa", name: "FaReact" },
    fontSize: "inherit",
    className: "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault",
  },
  pigijy: {
    inputName: "configuration.facebook",
    label: "Facebook",
    color: "primary",
    isChecked: false,
    disabled: false,
    disableTypography: false,
    "aria-label": "Input Base Mui",
    size: "medium",
    css: { minWidth: "50%" },
  },
  oqrbip: {
    inputName: "configuration.instagram",
    label: "Instagram",
    color: "primary",
    isChecked: false,
    disabled: false,
    disableTypography: false,
    "aria-label": "Input Base Mui",
    size: "medium",
  },
  xkxhry: {
    inputName: "configuration.linkedin",
    label: "LinkedIn",
    color: "primary",
    isChecked: false,
    disabled: false,
    disableTypography: false,
    "aria-label": "Input Base Mui",
    size: "medium",
  },
  jckckj: {
    inputName: "configuration.tiktok",
    label: "TikTok",
    color: "primary",
    isChecked: false,
    disabled: false,
    disableTypography: false,
    "aria-label": "Input Base Mui",
    size: "medium",
    css: { minWidth: "50%" },
  },
  axsmgg: {
    tag: "div",
    css: { gridTemplateColumns: "1fr 1fr", display: "flex", flexWrap: "wrap" },
  },
  clvqsl: {
    text: "Retour",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    color: "secondary",
    iconStart: false,
    iconEnd: false,
  },
  pthrch: {
    options: [{ label: "Default", value: "default" }],
    inputName: "type",
    label: "Type de compte",
    variant: "outlined",
    color: "secondary",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    multiple: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { maxWidth: "186px" },
  },
  hbealt: {
    tag: "div",
    css: { marginBottom: "16px", gap: "8px", display: "flex" },
  },
  guwxgg: { tag: "div" },
};

/** DEFAULT PROPS End */
