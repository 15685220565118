/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Image,
  ImageProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type EmptyResultProps = Partial<ContainerProps> & {
  action: ButtonProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  zfchiv: ImageProps,
  mbowad: TypographyProps,
  bwhmoj: ButtonProps,
  kxovti: PaperProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const EmptyResult: React.FC<EmptyResultProps> = (props) => {
  const { action, ...wrapperProps } = props;
  const { ROOT, zfchiv, mbowad, bwhmoj, kxovti } = defaultProps;

  return (
    <Container {...ROOT} {...wrapperProps}>
      <Paper {...kxovti}>
        <Image {...zfchiv} />
        <Typography {...mbowad} />
        <Button {...bwhmoj} {...action} />
      </Paper>
    </Container>
  );
};

export default EmptyResult;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: { textAlign: "center" },
  },
  zfchiv: {
    alt: "Image description for better SEO",
    src: "/images/welcome.svg",
    dynamic: true,
    css: { maxHeight: "280px" },
  },
  mbowad: {
    variant: "h2",
    text: "No data found",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "32px", textTransform: "uppercase", marginBottom: "16px" },
  },
  bwhmoj: {
    text: "Creer un compte",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    color: "secondary",
    iconStart: false,
    iconEnd: false,
  },
  kxovti: {
    square: true,
    variant: "elevation",
    elevation: 1,
    css: { padding: "22px", borderRadius: "22px" },
  },
};

/** DEFAULT PROPS End */
