/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type ResetPasswordFormProps = {
  form: FormProps,
  title: TypographyProps & {},
  resetPass: BoxProps,
  changePass: BoxProps,
  info: TypographyProps & {},
};

type DefaultProps = {
  ROOT: FormProps,
  rqsnzc: TextFieldProps,
  hgpffs: ButtonProps,
  zdmujz: PaperProps,
  fnerny: BoxProps,
  dbanqd: TypographyProps,
  nnbvwr: ContainerProps,
  egycxs: BoxProps,
  oobzne: BoxProps,
  qywnox: BoxProps,
  qtsxdu: TypographyProps,
  texfnv: TextFieldProps,
  rxtjcx: TextFieldProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { form, title, resetPass, changePass, info } = props;
  const {
    ROOT,
    rqsnzc,
    hgpffs,
    zdmujz,
    fnerny,
    dbanqd,
    nnbvwr,
    egycxs,
    oobzne,
    qywnox,
    qtsxdu,
    texfnv,
    rxtjcx,
  } = defaultProps;

  return (
    <Form {...ROOT} {...form}>
      <Container {...nnbvwr}>
        <Paper {...zdmujz}>
          <Box {...fnerny}>
            <Typography {...dbanqd} {...title} />
            <Typography {...qtsxdu} {...info} />
            <Box {...oobzne} {...resetPass}>
              <TextField {...rqsnzc} />
            </Box>
            <Box {...qywnox} {...changePass}>
              <TextField {...rxtjcx} />
              <TextField {...texfnv} />
            </Box>
          </Box>
          <Box {...egycxs}>
            <Button {...hgpffs} />
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default ResetPasswordForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { mode: "onSubmit" },
  rqsnzc: {
    inputName: "email",
    label: "E-mail",
    variant: "outlined",
    color: "secondary",
    type: "email",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { marginBottom: "16px" },
  },
  hgpffs: {
    text: "Envoyer",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
  },
  zdmujz: {
    square: false,
    variant: "outlined",
    elevation: 0,
    css: {
      padding: "42px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "26px",
    },
  },
  fnerny: { tag: "div", css: { marginBottom: "16px" } },
  dbanqd: {
    variant: "h1",
    text: "Réinitialisation",
    gutterBottom: false,
    noWrap: false,
    css: { marginBottom: "22px", textTransform: "uppercase", fontSize: "36px" },
  },
  nnbvwr: { maxWidth: "sm", disableGutters: false, fixed: false },
  egycxs: {
    tag: "div",
    css: { display: "flex", justifyContent: "space-between" },
  },
  oobzne: { tag: "div" },
  qywnox: { tag: "div" },
  qtsxdu: {
    variant: "body1",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { marginBottom: "16px" },
  },
  texfnv: {
    inputName: "passwordConfirmation",
    label: "Confirmation",
    variant: "outlined",
    color: "secondary",
    type: "password",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { marginBottom: "16px" },
  },
  rxtjcx: {
    inputName: "password",
    label: "Mot de passe",
    variant: "outlined",
    color: "secondary",
    type: "password",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { marginBottom: "16px" },
  },
};

/** DEFAULT PROPS End */
