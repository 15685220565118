/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type AppLayoutProps = Partial<BoxProps> & {
  content: ContainerProps,
  login: ButtonProps & {},
  user: BoxProps,
  avatar: AvatarProps,
  name: TypographyProps & {},
  logout: ButtonIconProps,
  notification: ButtonIconProps,
  breadcrumb: PaperProps,
  menu: PaperProps,
};

type DefaultProps = {
  ROOT: BoxProps,
  yblulg: ContainerProps,
  tfkgna: ContainerProps,
  qsmqml: ContainerProps,
  iefcpz: ImageProps,
  rbkdmc: ButtonProps,
  mvksmn: BoxProps,
  ovulsq: BoxProps,
  hwwoyz: ContainerProps,
  kbohfw: BoxProps,
  vlorby: AvatarProps,
  ovuzuc: TypographyProps,
  bewnoz: ButtonIconProps,
  zsogdp: IconProps,
  lgmire: ButtonIconProps,
  djtdom: IconProps,
  svszqb: PaperProps,
  jlkaiu: PaperProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const {
    content,
    login,
    user,
    avatar,
    name,
    logout,
    notification,
    breadcrumb,
    menu,
    ...wrapperProps
  } = props;
  const {
    ROOT,
    yblulg,
    tfkgna,
    qsmqml,
    iefcpz,
    rbkdmc,
    mvksmn,
    ovulsq,
    hwwoyz,
    kbohfw,
    vlorby,
    ovuzuc,
    bewnoz,
    zsogdp,
    lgmire,
    djtdom,
    svszqb,
    jlkaiu,
  } = defaultProps;

  return (
    <Box {...ROOT} {...wrapperProps}>
      <Box {...ovulsq}>
        <Container {...yblulg}>
          <Image {...iefcpz} />
          <Box {...mvksmn}>
            <Paper {...jlkaiu} {...menu} />
            <Button {...rbkdmc} {...login} />
            <Box {...kbohfw} {...user}>
              <Avatar {...vlorby} {...avatar} />
              <Typography {...ovuzuc} {...name} />
              <ButtonIcon {...bewnoz} Icon={<Icon {...zsogdp} />} {...logout} />
              <ButtonIcon
                {...lgmire}
                Icon={<Icon {...djtdom} />}
                {...notification}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container {...hwwoyz}>
        <Paper {...svszqb} {...breadcrumb} />
      </Container>
      <Container {...tfkgna} {...content} />
      <Container {...qsmqml} />
    </Box>
  );
};

export default AppLayout;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    tag: "div",
    css: {
      minHeight: "100vh",
      background: "url(/images/bg-app.jpg) top / cover no-repeat fixed",
      display: "flex",
      flexDirection: "column",
      ".mui-dark &": {
        background: "url(/images/bg-app-dark.jpg) top / cover no-repeat fixed",
      },
    },
  },
  yblulg: {
    maxWidth: "xl",
    disableGutters: false,
    fixed: false,
    css: {
      paddingTop: "20px",
      paddingBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  tfkgna: {
    maxWidth: "lg",
    disableGutters: false,
    fixed: false,
    css: {
      flexGrow: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  qsmqml: {
    maxWidth: "lg",
    disableGutters: false,
    fixed: false,
    css: { marginTop: "16px", marginBottom: "16px" },
  },
  iefcpz: {
    alt: "Image description for better SEO",
    src: "/images/logo.png",
    href: "/",
    dynamic: false,
    css: { width: "280px" },
  },
  rbkdmc: {
    text: "Se connecter",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "large",
    variant: "contained",
    color: "primary",
    iconStart: false,
    iconEnd: false,
    href: "/login",
  },
  mvksmn: {
    tag: "div",
    css: {
      flexGrow: "1",
      gap: "8px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  ovulsq: {
    tag: "div",
    css: {
      zIndex: "100",
      position: "sticky",
      top: "0px",
      background: "url(/images/bg-app.jpg) top / cover no-repeat fixed",
      ".mui-dark &": {
        background: "url(/images/bg-app-dark.jpg) top / cover no-repeat fixed",
      },
    },
  },
  hwwoyz: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: { marginTop: "15px" },
  },
  kbohfw: {
    tag: "div",
    css: {
      gap: "8px",
      marginLeft: "8px",
      display: "flex",
      alignItems: "center",
    },
  },
  vlorby: {
    variant: "circular",
    initial: "EE",
    component: "div",
    css: {
      backgroundColor: "rgba(0,0,0,1)",
      ".mui-dark &": { backgroundColor: "rgba(255,255,255, 1)" },
    },
  },
  ovuzuc: {
    variant: "h3",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "16px" },
  },
  bewnoz: {
    type: "button",
    disabled: false,
    disableFocusRipple: false,
    disableRipple: false,
    color: "secondary",
    css: { backgroundColor: "rgba(255,255,255, 1)" },
  },
  zsogdp: {
    color: "inherit",
    icon: { name: "RiLogoutCircleRLine", library: "ri" },
    fontSize: "inherit",
  },
  lgmire: {
    type: "button",
    disabled: false,
    disableFocusRipple: false,
    disableRipple: false,
    color: "primary",
    css: { backgroundColor: "rgba(255,255,255, 1)" },
  },
  djtdom: {
    color: "inherit",
    icon: { name: "IoIosNotifications", library: "io" },
    fontSize: "inherit",
  },
  svszqb: {
    square: true,
    variant: "elevation",
    elevation: 1,
    css: {
      borderRadius: "26px",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  jlkaiu: {
    square: true,
    variant: "elevation",
    elevation: 1,
    css: { borderRadius: "26px" },
  },
};

/** DEFAULT PROPS End */
