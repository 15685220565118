/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type ModelItemProps = {
  item: ContainerProps,
  icon: IconProps,
  name: TypographyProps & {},
  description: TypographyProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  jwxabj: BoxProps,
  kvblus: IconProps,
  zdxubp: TypographyProps,
  atundd: TypographyProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const ModelItem: React.FC<ModelItemProps> = (props) => {
  const { item, icon, name, description } = props;
  const { ROOT, jwxabj, kvblus, zdxubp, atundd } = defaultProps;

  return (
    <Container {...ROOT} {...item}>
      <Box {...jwxabj}>
        <Typography {...zdxubp} {...name} />
        <Typography {...atundd} {...description} />
      </Box>
      <Icon {...kvblus} {...icon} />
    </Container>
  );
};

export default ModelItem;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "xs",
    disableGutters: false,
    fixed: false,
    css: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#0B0B0B",
      boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
      gap: "8px",
      borderRadius: "16px",
      marginBottom: "12px",
      paddingTop: "12px",
      paddingBottom: "12px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      ":hover": { boxShadow: "rgba(240, 46, 170, 0.4) 0px 0px   " },
    },
  },
  jwxabj: { tag: "div", css: { flexGrow: "1" } },
  kvblus: {
    color: "secondary",
    icon: { name: "FaCaretRight", library: "fa" },
    fontSize: "inherit",
    css: { fontSize: "24px" },
  },
  zdxubp: {
    variant: "h3",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "16px" },
  },
  atundd: {
    variant: "caption",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "14px", color: "#5C5D63" },
  },
};

/** DEFAULT PROPS End */
