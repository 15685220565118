/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type UserFormProps = {
  email: TextFieldProps,
  type: SelectFieldProps,
};

type DefaultProps = {
  ROOT: ContainerProps,
  lytxjk: BoxProps,
  cxwdnt: TextFieldProps,
  cecthq: TextFieldProps,
  pjxtbv: TextFieldProps,
  nbyfak: SelectFieldProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const UserForm: React.FC<UserFormProps> = (props) => {
  const { email, type } = props;
  const { ROOT, lytxjk, cxwdnt, cecthq, pjxtbv, nbyfak } = defaultProps;

  return (
    <Container {...ROOT}>
      <Box {...lytxjk}>
        <TextField {...cxwdnt} />
        <TextField {...cecthq} />
      </Box>
      <TextField {...pjxtbv} {...email} />
      <SelectField {...nbyfak} {...type} />
    </Container>
  );
};

export default UserForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "sm",
    disableGutters: false,
    fixed: false,
    css: { paddingTop: "16px" },
  },
  lytxjk: {
    tag: "div",
    css: { gap: "8px", marginBottom: "16px", display: "flex" },
  },
  cxwdnt: {
    inputName: "firstName",
    label: "Prénom",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
  },
  cecthq: {
    inputName: "lastName",
    label: "Nom",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
  },
  pjxtbv: {
    inputName: "email",
    label: "Email",
    variant: "outlined",
    color: "primary",
    type: "email",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
    css: { marginBottom: "16px" },
  },
  nbyfak: {
    options: [{ label: "Default", value: "default" }],
    inputName: "type",
    label: "Role",
    variant: "outlined",
    color: "primary",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    multiple: false,
    readOnly: false,
    required: true,
    size: "small",
  },
};

/** DEFAULT PROPS End */
