import React, { useCallback } from "react";
import { Count, SocialAccount } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { accountSelector } from "@/store/accounts";
import { Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { Provider } from "@/interfaces";

type Props = {
  accountId: string;
  defaultPeriod?: string | Date;
  maxDate?: Dayjs;
};

const MounthPicker: React.FC<Props> = (props) => {
  const { accountId, defaultPeriod, maxDate } = props;
  const { control, setValue, getValues } = useFormContext();
  const [strapi] = useStrapi();
  const account = useRecoilValue(accountSelector(accountId));

  const getSocialInsights = useCallback(
    async (
      socialAccount?: SocialAccount,
      period?: { start: string; end: string }
    ) => {
      // recuperer la liste des statistics pour le mois selectioné
      if (!socialAccount?.id || !period) return undefined;
      const res = await strapi?.find("social-statistics", {
        populate: "*",
        filters: {
          date: {
            $gte: period.start,
            $lte: period.end,
          },
          social_account: {
            id: {
              $eq: socialAccount.id,
            },
          },
        },
        sort: "date:desc",
        pagination: {
          limit: 1,
          start: 0,
        },
      });
      if (res?.data?.[0]) {
        return { ...res.data[0].statistics, date: res.data[0].date };
      }
    },
    [strapi]
  );

  const setSocialInsights = useCallback(
    (type: Provider, value?: Count) => {
      if (value) {
        const { comment, followers, impressions, interactions, posts } =
          getValues(type) as Count;

        setValue(type, {
          comment: comment || value.comment,
          followers: followers || value.followers || 0,
          impressions: impressions || value.impressions || 0,
          interactions: interactions || value.interactions || 0,
          posts: posts || value.posts || 0,
        });
        toast(`${type} values are updated`, { type: "success" });
      } else toast(`Missing ${type} values`, { type: "error" });
    },
    [getValues, setValue]
  );

  const onSyncDate = useCallback(
    async (date?: Dayjs) => {
      if (date) {
        const period = {
          start: date.startOf("month").format("YYYY-MM-DD"),
          end: date.endOf("month").format("YYYY-MM-DD"),
        };

        const facebook = await getSocialInsights(
          account?.facebook_account,
          period
        );
        setSocialInsights("facebook", facebook);

        const instagram = await getSocialInsights(
          account?.instagram_account,
          period
        );
        setSocialInsights("instagram", instagram);

        const tiktok = await getSocialInsights(account?.tiktok_account, period);
        setSocialInsights("tiktok", tiktok);

        const linkedin = await getSocialInsights(
          account?.linkedin_account,
          period
        );
        setSocialInsights("linkedin", linkedin);
      }
    },
    [
      account?.facebook_account,
      account?.instagram_account,
      account?.linkedin_account,
      account?.tiktok_account,
      getSocialInsights,
      setSocialInsights,
    ]
  );

  return (
    <Controller
      control={control}
      name="period"
      render={({
        field: { ref, onBlur, name, onChange, value = null, ...field },
      }) => (
        <>
          <MobileDatePicker
            inputRef={ref}
            label="Période"
            value={value}
            onChange={onChange}
            slotProps={{
              calendarHeader: {
                slotProps: {
                  nextIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  previousIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  switchViewButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                },
              },
            }}
            localeText={{ cancelButtonLabel: "Annuler" }}
            views={["month", "year"]}
            disableFuture
            disabled={!!defaultPeriod}
            maxDate={maxDate}
            monthsPerRow={4}
            {...field}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ padding: "6px 14px" }}
            disabled={!value}
            onClick={() => onSyncDate(value)}>
            Synchroniser
          </Button>
        </>
      )}
    />
  );
};

export default MounthPicker;
