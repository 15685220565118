import { UserList } from "@/components";
import { EmailModal } from "@/components/features/notificationCenter";
import UserModal from "@/components/features/user/UserModal";
import Layout, { Modal } from "@/components/layout";
import { useStrapiFetch } from "@/hooks/fetch";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { Add, Home, People } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Icon } from "@repleek/mui";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";

const userTypeLabel = {
  customer: "Client",
  admin: "Administrateur",
  cm: "Community Manager",
};

const Users: React.FC = () => {
  const [strapi, { loading: fetching }] = useStrapi();
  const [{ data = [], loading }, { refresh }] = useStrapiFetch("/me/users");
  const [notification, setNotification] = useState<
    { email: string; resetToken?: string } | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] = useState<Partial<User> | undefined>(
    undefined
  );
  const [elToDelete, setElToDelete] = useState<User | undefined>(undefined);

  const onNotify = useCallback(
    async (email?: string) => {
      if (email) {
        setNotification({ email });
        const resetToken = await strapi?.get("/auth/:email/reset-token", {
          ":email": email,
        });
        if (resetToken) setNotification({ email, resetToken });
        else setNotification(undefined);
      }
    },
    [strapi]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "firstName",
        headerName: "Prénom",
        minWidth: 110,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => row?.firstName,
      },
      {
        field: "lastName",
        headerName: "Nom",
        minWidth: 180,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => row?.lastName,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 260,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => row?.email,
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 160,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          const type = row.type as keyof typeof userTypeLabel;
          return (type && userTypeLabel[type]) || "";
        },
      },
      {
        field: "connected",
        headerName: "Connecté(e) il y a",
        minWidth: 160,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.lastConnexion && dayjs(row.lastConnexion).fromNow();
        },
      },
      {
        field: "actions",
        sortable: false,
        disableColumnMenu: true,
        type: "actions",
        minWidth: 160,
        getActions: ({ row }) => [
          <IconButton
            color="primary"
            sx={{ width: "32px", height: "32px", fontSize: "18px" }}
            onClick={() => onNotify(row.email)}>
            <Icon
              icon={{
                library: "io5",
                name: "IoPaperPlaneOutline",
              }}
              fontSize="inherit"
            />
          </IconButton>,
          <IconButton
            color="secondary"
            sx={{ width: "32px", height: "32px", fontSize: "18px" }}
            onClick={() => setSelectedUser(row)}>
            <Icon
              icon={{
                library: "hi2",
                name: "HiOutlineCog6Tooth",
              }}
              fontSize="inherit"
            />
          </IconButton>,
          <IconButton
            color="secondary"
            sx={{ width: "32px", height: "32px", fontSize: "18px" }}
            onClick={() => setElToDelete(row)}>
            <Icon
              icon={{
                library: "io5",
                name: "IoTrashOutline",
              }}
              fontSize="inherit"
            />
          </IconButton>,
        ],
        valueGetter: ({ row }) => row?.statistics?.posts,
      },
    ],
    [onNotify]
  );

  const onDeleteUser = useCallback(async () => {
    if (elToDelete?.id) {
      const res = (await strapi?.delete("me/users", elToDelete.id)) as any;
      if (res?.id) {
        setElToDelete(undefined);
        refresh?.();
      }
    }
  }, [elToDelete, strapi, refresh]);

  const userSelectedName = useMemo(
    () =>
      [elToDelete?.firstName, elToDelete?.lastName]
        .filter((v) => !!v)
        .join(" ") || elToDelete?.email,
    [elToDelete]
  );

  return (
    <Layout
      title="Pistache - utilisateurs"
      mode="ADMIN"
      breadCrumbs={[
        { label: "Comptes", icon: Home, href: "/" },
        { label: "Utilisateurs", icon: People },
      ]}>
      <UserList
        users={{
          children: (
            <DataGrid
              rows={data}
              columns={columns}
              rowSelection={false}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              loading={loading}
              slots={{
                toolbar: () => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "8px",
                      pt: 1,
                      pr: 1,
                    }}>
                    <GridToolbarQuickFilter
                      InputProps={{
                        placeholder: "Recherche ...",
                        sx: { borderRadius: "32px", maxHeight: "40px" },
                      }}
                      variant="outlined"
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => setSelectedUser({})}>
                      <Add />
                    </IconButton>
                  </Box>
                ),
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          ),
        }}
      />
      <EmailModal
        open={!!notification}
        loading={fetching}
        models={["resetPassword", "default"]}
        content={{
          resetPassword: {
            link: `https://dashboard.agence-pistache.fr/reset-password?token=${notification?.resetToken}`,
          },
        }}
        email={notification?.email}
        onClose={() => setNotification(undefined)}
      />

      <UserModal
        open={!!selectedUser}
        user={selectedUser}
        onClose={() => setSelectedUser(undefined)}
        onSave={refresh}
      />

      <Modal
        open={!!elToDelete}
        onClose={() => setElToDelete(undefined)}
        onConfirm={onDeleteUser}
        loading={fetching}
        title="Suppression d'utilisateur"
        message={`Êtes-vous sûr de vouloir supprimer l'utilisateur <b><u>${userSelectedName}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`}
      />
    </Layout>
  );
};

export default Users;
