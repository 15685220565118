import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AccountDetail,
  ListReport,
  NotFound,
  ReportingItem,
} from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { Container, Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { accountSelector } from "@/store/accounts";
import SocialDetail from "@/components/features/account/SocialDetail";
import {
  reportingListSelector,
  reportingMeta,
  reportingQuery,
} from "@/store/reporting";
import { useStrapi } from "@/providers/StrapiProvider";
import { Modal } from "@/components/layout";
import { EmailModal } from "@/components/features/notificationCenter";
import { BusinessCenter, Home } from "@mui/icons-material";
import BreadCrumb from "@/components/layout/BreadCrumb";
import { useSocialAccess } from "@/hooks/account";
import dayjs from "dayjs";

const Account: React.FC = () => {
  const { id } = useParams() as any;

  const [query, setQuery] = useRecoilState(reportingQuery(id));
  const meta = useRecoilValue(reportingMeta(id));

  const pageCount = useMemo(
    () => (meta ? meta.pagination.pageCount || 0 : 0),
    [meta]
  );

  const handlePaginate = useCallback(
    (_event: any, page: number) => {
      setQuery((q) => ({ ...q, pagination: { ...q.pagination, page } }));
    },
    [setQuery]
  );

  if (!id) return <NotFound />;

  return (
    <Layout title="Pistache - nouveau compte" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Details id={id} />
      </Suspense>

      <ListReport
        reporting={{
          children: (
            <Suspense fallback={<PistacheLoader />}>
              <Reporting id={id} />
            </Suspense>
          ),
        }}
        pagination={{
          children: (
            <Pagination
              sx={{
                background: "white",
                border: "1px solid black",
                borderRadius: "32px",
                padding: "8px",
              }}
              color="secondary"
              hidden={pageCount <= 1}
              count={pageCount}
              page={query.pagination.page}
              onChange={handlePaginate}
            />
          ),
        }}
        newReporting={{ href: `/${id}/new-reporting` }}
      />
    </Layout>
  );
};

const Details: React.FC<{ id: string }> = ({ id }) => {
  const account = useRecoilValue(accountSelector(id));
  const [notification, setNotification] = useState(false);
  const { facebook, instagram, linkedin, tiktok } = useSocialAccess(
    account?.configuration
  );
  if (!account?.id) return <NotFound />;

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          background: "white",
          padding: "10px",
          borderRadius: "26px",
          border: "1px solid black",
        }}>
        <BreadCrumb
          breadCrumbs={[
            { label: "Comptes", icon: Home, href: "/" },
            { label: account.name, icon: BusinessCenter },
          ]}
        />
      </Container>
      <AccountDetail
        editBtn={{
          href: `/edit-account/${id}`,
        }}
        logo={{
          src:
            account?.facebook_account?.picture || "/images/logo-pistache.jpg",
        }}
        name={{ text: account.name }}
        email={{ text: account.email }}
        notification={{ onClick: () => setNotification(true) }}
      />
      {facebook && (
        <SocialDetail
          type={"facebook"}
          header
          status={
            account?.facebook_account?.status ||
            (account.facebook_account && "LINKED")
          }
          status_message={account?.facebook_account?.status_message}
          lastUpdated={account.facebook_account?.last_sync}
        />
      )}
      {instagram && (
        <SocialDetail
          type={"instagram"}
          status={
            account?.instagram_account?.status ||
            (account.instagram_account && "LINKED")
          }
          status_message={account?.instagram_account?.status_message}
          lastUpdated={account.instagram_account?.last_sync}
        />
      )}
      {tiktok && (
        <SocialDetail
          type={"tiktok"}
          status={
            account?.tiktok_account?.status ||
            (account.tiktok_account && "LINKED")
          }
          status_message={account?.tiktok_account?.status_message}
          lastUpdated={account.tiktok_account?.last_sync}
        />
      )}
      {linkedin && (
        <SocialDetail
          type={"linkedin"}
          status={
            account?.linkedin_account?.status ||
            (account.linkedin_account && "LINKED")
          }
          status_message={account?.linkedin_account?.status_message}
          lastUpdated={account.linkedin_account?.last_sync}
        />
      )}
      <EmailModal
        open={notification}
        content={{
          resetPassword: {
            link: `https://dashboard.agence-pistache.fr/reset-password?token=${account?.resetToken}`,
          },
          welcomeEmail: {
            link: `https://dashboard.agence-pistache.fr/reset-password?token=${account?.resetToken}&name=${account?.name}`,
          },
        }}
        email={account.email}
        onClose={() => setNotification(false)}
      />
    </>
  );
};

const Reporting: React.FC<{ id: string }> = ({ id: accountId }) => {
  const [strapi] = useStrapi();
  const [elToDelete, setElToDelete] = useState<
    { id: number; name?: string } | undefined
  >(undefined);

  const list = useRecoilValue(reportingListSelector(accountId));
  const [query, setQuery] = useRecoilState(reportingQuery(accountId));
  const setReportingMeta = useSetRecoilState(reportingMeta(accountId));
  const refresh = useRecoilRefresher_UNSTABLE(reportingListSelector(accountId));

  const onDelete = useCallback(async () => {
    if (elToDelete) {
      const res = await strapi?.delete("social-reports", elToDelete.id);
      if (res?.data) {
        setElToDelete(undefined);
        if (query.pagination.page > 1)
          setQuery((q) => ({ ...q, pagination: { ...q.pagination, page: 1 } }));
        else refresh();
      }
    }
  }, [elToDelete, strapi, query.pagination.page, setQuery, refresh]);

  useEffect(() => {
    setReportingMeta(list?.meta);
  }, [list?.meta, setReportingMeta]);

  return (
    <>
      {list?.data?.map(({ name, id, lastView }) => (
        <ReportingItem
          name={{ text: name }}
          key={id}
          editBtn={{
            href: `/${accountId}/edit-reporting/${id}`,
          }}
          deleteBtn={{ onClick: () => id && setElToDelete({ id, name }) }}
          viewBtn={{
            href: `/${accountId}/reporting/${id}`,
          }}
          lastView={{
            text: lastView
              ? `Consulté ${dayjs(lastView).fromNow()}`
              : "Non consulté",
          }}
        />
      ))}

      <Modal
        open={!!elToDelete}
        onClose={() => setElToDelete(undefined)}
        onConfirm={onDelete}
        title="Suppression de rapport"
        message={`Êtes-vous sûr de vouloir supprimer le rapport <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`}
      />
    </>
  );
};

export default Account;
