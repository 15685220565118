/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type LoginFormProps = Partial<FormProps> & {
  form: FormProps,
};

type DefaultProps = {
  ROOT: FormProps,
  exofph: TextFieldProps,
  cmxnrv: TextFieldProps,
  cdsowp: ButtonProps,
  wdqimd: PaperProps,
  rfyfzf: BoxProps,
  icwlms: TypographyProps,
  gxiqro: ContainerProps,
  ctyjdo: BoxProps,
  rgygou: ButtonProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { form, ...wrapperProps } = props;
  const {
    ROOT,
    exofph,
    cmxnrv,
    cdsowp,
    wdqimd,
    rfyfzf,
    icwlms,
    gxiqro,
    ctyjdo,
    rgygou,
  } = defaultProps;

  return (
    <Form {...ROOT} {...form} {...wrapperProps}>
      <Container {...gxiqro}>
        <Paper {...wdqimd}>
          <Box {...rfyfzf}>
            <Typography {...icwlms} />
            <TextField {...exofph} />
            <TextField {...cmxnrv} />
          </Box>
          <Box {...ctyjdo}>
            <Button {...rgygou} />
            <Button {...cdsowp} />
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default LoginForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { mode: "onSubmit" },
  exofph: {
    inputName: "email",
    label: "E-mail",
    variant: "outlined",
    color: "secondary",
    type: "email",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { marginBottom: "16px" },
  },
  cmxnrv: {
    inputName: "password",
    label: "Mot de passe",
    variant: "outlined",
    color: "secondary",
    type: "password",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
  },
  cdsowp: {
    text: "Connexion",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
  },
  wdqimd: {
    square: false,
    variant: "outlined",
    elevation: 0,
    css: {
      padding: "36px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "26px",
    },
  },
  rfyfzf: { tag: "div", css: { marginBottom: "16px" } },
  icwlms: {
    variant: "h1",
    text: "Connexion",
    gutterBottom: false,
    noWrap: false,
    css: {
      marginBottom: "22px",
      textTransform: "uppercase",
      fontSize: "52px",
      fontFamily: "Unbounded",
      fontStyle: "normal",
      fontWeight: "600",
    },
  },
  gxiqro: { maxWidth: "sm", disableGutters: false, fixed: false },
  ctyjdo: {
    tag: "div",
    css: { display: "flex", justifyContent: "space-between" },
  },
  rgygou: {
    text: "Mot de passe oublié ?",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "outlined",
    color: "secondary",
    iconStart: false,
    iconEnd: false,
    href: "/reset-password",
  },
};

/** DEFAULT PROPS End */
