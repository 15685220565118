/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import { Box, BoxProps, Container, ContainerProps } from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type UserListProps = {
  users: BoxProps,
};

type DefaultProps = {
  ROOT: BoxProps,
  pgpbvy: ContainerProps,
  ogwomo: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const UserList: React.FC<UserListProps> = (props) => {
  const { users } = props;
  const { ROOT, pgpbvy, ogwomo } = defaultProps;

  return (
    <Box {...ROOT}>
      <Container {...pgpbvy}>
        <Box {...ogwomo} {...users} />
      </Container>
    </Box>
  );
};

export default UserList;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { tag: "div" },
  pgpbvy: { maxWidth: "lg", disableGutters: false, fixed: false },
  ogwomo: {
    tag: "div",
    css: {
      backgroundColor: "rgba(255,255,255, 1)",
      borderRadius: "12px",
      boxShadow:
        "rgba(240, 46, 170, 0.4) 5px 5px , rgba(240, 46, 170, 0.3) 10px 10px",
      paddingLeft: "26px",
      paddingRight: "26px",
      paddingTop: "24px",
      paddingBottom: "24px",
      height: "488px",
    },
  },
};

/** DEFAULT PROPS End */
