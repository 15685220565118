import React, { useCallback, useMemo, useState } from "react";
import { Modal, ModalProps, PistacheLoader } from "@/components/layout";
import { Box, Step, StepButton, Stepper } from "@mui/material";
import ModelItem from "./ModelItem";
import EmailForm from "./EmailForm";
import EmailResume from "./EmailResume";
import { useStrapi } from "@/providers/StrapiProvider";
import { EmailModel, Template, EmailType } from "@/interfaces";
import { toast } from "react-toastify";

type Props = Pick<ModalProps, "open" | "onClose"> & {
  model?: keyof EmailModel;
  models?: (keyof EmailModel)[];
  email?: string;
  content?: { [k in Template]?: Partial<EmailType> };
  loading?: boolean;
};

const EmailModal: React.FC<Props> = (props) => {
  const { model, models, email, content, onClose, ...attr } = props;
  const [selectedModel, setSelectedModel] = useState(model);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState<EmailType>();
  const [strapi, { loading }] = useStrapi();

  const modelDefinition = useMemo<EmailModel>(() => {
    const model = {
      welcomeEmail: {
        value: {
          subject: "Bienvenue sur notre plateforme !",
          title: "🚀 Bienvenue sur notre plateforme 🚀",
          message:
            "Cliquez sur le bouton ci-dessous pour activer votre compte.",
          button_name: "Activer mon compte",
          link: "https://dashboard.agence-pistache.fr",
        },
        name: "Email de bienvenue",
        description: "Message de bienvenue pour les nouveaux utilisateurs.",
      },
      resetPassword: {
        value: {
          subject: "Réinitialisation de votre mot de passe",
          title: "Réinitialisation de votre mot de passe",
          message:
            "Cliquez sur le bouton ci-dessous pour réinitialiser votre mot de passe.",
          button_name: "Réinitialiser mon mot de passe",
          link: "https://dashboard.agence-pistache.fr",
        },
        name: "Réinitialisation de mot de passe",
        description:
          "Email envoyé aux utilisateurs pour réinitialiser leur mot de passe.",
      },
      reporting: {
        value: {
          subject: "Nouveau rapport disponnible!",
          title: "🔔 Nouveau rapport disponnible 🔔",
          message:
            "Votre rapport récemment généré est maintenant accessible sur votre compte. N'hésitez pas à le consulter dès à présent pour prendre connaissance de son contenu.",
          button_name: "Consulter mon compte",
          link: "https://dashboard.agence-pistache.fr",
        },
        name: "Email de notification (reporting)",
        description: "Email de notification pour les rapports ou les alertes.",
      },
      default: {
        value: {
          subject: "",
          title: "",
          message: "",
          button_name: "Mon compte",
          link: "https://dashboard.agence-pistache.fr",
        },
        name: "Rédiger votre email",
        description:
          "Modèle d'email par défaut avec la possibilité pour l'utilisateur de personnaliser le contenu.",
      },
    };

    if (models) {
      return models.reduce((acc, curr) => {
        if (model[curr]) acc[curr] = model[curr];
        return acc;
      }, {} as EmailModel);
    }

    return model;
  }, [models]);
  const defaultValues = useMemo(() => {
    if (data) return data;
    return {
      ...modelDefinition[selectedModel || "default"].value,
      ...content?.[selectedModel || "default"],
      email,
    };
  }, [content, data, email, modelDefinition, selectedModel]);

  const setNextStep = useCallback(() => {
    const currStep = activeStep;
    setActiveStep(currStep + 1);
  }, [activeStep]);

  const setBackStep = useCallback(() => {
    const currStep = activeStep;
    setActiveStep(currStep - 1);
  }, [activeStep]);

  const onSelectModel = useCallback(
    (model: keyof EmailModel) => {
      setSelectedModel(model);
      setNextStep();
    },
    [setNextStep]
  );

  const handleSave = useCallback(
    (values: EmailType) => {
      setData(values);
      setNextStep();
    },
    [setNextStep]
  );

  const onCloseModal = useCallback(() => {
    onClose();
    setActiveStep(0);
    setSelectedModel(undefined);
  }, [onClose]);

  const sendEmail = useCallback(async () => {
    if (data) {
      const res = await strapi?.post("send-email", {}, data);
      if (res) {
        toast.success("Notification envoyée");
        onCloseModal();
      }
    }
  }, [data, onCloseModal, strapi]);

  const steps = useMemo(() => {
    const steps = [];
    if (!model)
      steps.push({
        label: "Choisissez un modèle",
        step: Object.entries(modelDefinition).map(
          ([model, { name, description }]) => (
            <ModelItem
              key={model}
              item={{
                onClick: () => onSelectModel(model as keyof EmailModel),
                style: { transition: "all 300ms" },
              }}
              icon={{
                ...(selectedModel === model && {
                  icon: { library: "io5", name: "IoCheckmarkCircle" },
                  color: "primary",
                }),
              }}
              name={{ text: name }}
              description={{ text: description }}
            />
          )
        ),
      });
    return [
      ...steps,
      {
        label: "Rédigez votre e-mail",
        step: (
          <EmailForm
            back={{
              text: "Retour",
              onClick: () => {
                setBackStep();
                setData(undefined);
              },
            }}
            email={{ disabled: !!email, inputName: "email" }}
          />
        ),
      },
      {
        label: "Confirmation",
        step: (
          <EmailResume
            email={{ email }}
            subject={{ subject: data?.subject }}
            title={{ text: data?.title }}
            message={{ text: data?.message }}
            buttonName={{ text: data?.button_name }}
            edit={{ onClick: setBackStep }}
            validate={{ onClick: sendEmail }}
          />
        ),
      },
    ];
  }, [
    data?.button_name,
    data?.message,
    data?.subject,
    data?.title,
    email,
    model,
    modelDefinition,
    onSelectModel,
    selectedModel,
    sendEmail,
    setBackStep,
  ]);

  return (
    <Modal
      title={"Centre de notification par e-mail"}
      icon={{ icon: { library: "io", name: "IoIosPaperPlane" } }}
      width="md"
      noActions
      onClose={onCloseModal}
      form={{ defaultValues, onSubmit: handleSave }}
      {...attr}>
      <Box sx={{ width: "100%", mt: 2, mb: 2, minHeight: "240px" }}>
        <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map(({ label }, index) => (
            <Step key={label} completed={index < activeStep}>
              <StepButton color="inherit" disableRipple>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {loading || attr.loading ? <PistacheLoader /> : steps[activeStep].step}
      </Box>
    </Modal>
  );
};

export default EmailModal;
