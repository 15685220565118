import {
  Box,
  CircularProgress,
  ContainerProps,
  Modal as MuiModal,
} from "@mui/material";
import React, { PropsWithChildren } from "react";
import ModalAlert from "./ModalAlert";
import { FormProps, IconProps } from "@repleek/mui";
import Loading from "./Loading";

export type ModalProps = {
  open: boolean;
  title: string;
  message?: string;
  noActions?: boolean;
  loading?: boolean;
  icon?: IconProps;
  width?: ContainerProps["maxWidth"];
  form?: FormProps;
  onClose: () => void;
  onConfirm?: () => void;
};

export const Modal: React.FC<PropsWithChildren<ModalProps>> = (props) => {
  const {
    open,
    title,
    message,
    noActions,
    loading,
    icon,
    children,
    width = "xs",
    form = {},
    onClose,
    onConfirm,
  } = props;

  return (
    <MuiModal
      open={open}
      onClose={loading ? () => {} : onClose}
      keepMounted={false}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 8,
        }}>
        <ModalAlert
          alertTiltle={{ text: title }}
          cancel={{ onClick: onClose, hidden: noActions || loading }}
          confirm={{
            hidden: !onConfirm || noActions || loading,
            onClick: onConfirm,
          }}
          message={{ text: message, hidden: !message || loading }}
          icon={{ ...icon }}
          alertContent={{ children: loading ? <PistacheLoader /> : children }}
          container={{ maxWidth: width }}
          form={form}
        />
      </Box>
    </MuiModal>
  );
};

export const PistacheLoader: React.FC = (props) => {
  return (
    <Loading
      loader={{ children: <CircularProgress size={100} /> }}
      logo={{
        css: {
          position: "absolute",
          top: "47%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 52,
        },
      }}
    />
  );
};
