/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Fragment,
  FragmentProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type SocialStatusProps = {
  header: BoxProps,
  icon: IconProps,
  status: TypographyProps & {},
  lastUpdated: TypographyProps & {},
  action: BoxProps,
  statusMessage: FragmentProps,
};

type DefaultProps = {
  ROOT: ContainerProps,
  nkfegl: BoxProps,
  imontj: IconProps,
  vwtycc: BoxProps,
  mmckbe: BoxProps,
  gpzjgt: TypographyProps,
  voojwx: TypographyProps,
  mhitik: TypographyProps,
  cvhhdj: TypographyProps,
  zdltvi: BoxProps,
  luewid: FragmentProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const SocialStatus: React.FC<SocialStatusProps> = (props) => {
  const { header, icon, status, lastUpdated, action, statusMessage } = props;
  const {
    ROOT,
    nkfegl,
    imontj,
    vwtycc,
    mmckbe,
    gpzjgt,
    voojwx,
    mhitik,
    cvhhdj,
    zdltvi,
    luewid,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <Icon {...imontj} {...icon} />
      <Box {...vwtycc}>
        <Box {...nkfegl} {...header}>
          <Typography {...mhitik} />
          <Typography {...cvhhdj} />
        </Box>
        <Box {...mmckbe}>
          <Fragment {...luewid} {...statusMessage} />
          <Typography {...gpzjgt} {...status} />
          <Typography {...voojwx} {...lastUpdated} />
        </Box>
      </Box>
      <Box {...zdltvi} {...action} />
    </Container>
  );
};

export default SocialStatus;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: {
      marginBottom: "16px",
      gap: "16px",
      display: "flex",
      alignItems: "flex-end",
    },
  },
  nkfegl: {
    tag: "div",
    css: {
      backgroundColor: "rgba(255,255,255, 1)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000",
      flexGrow: "1",
      borderRadius: "22px",
      marginBottom: "16px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "4px",
      paddingBottom: "4px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  imontj: {
    color: "inherit",
    icon: { name: "SlSocialInstagram", library: "sl" },
    fontSize: "inherit",
    css: {
      width: "32px",
      height: "32px",
      fontSize: "32px",
      marginBottom: "8px",
    },
  },
  vwtycc: { tag: "div", css: { flexGrow: "1" } },
  mmckbe: {
    tag: "div",
    css: {
      backgroundColor: "rgba(255,255,255, 1)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000",
      flexGrow: "1",
      borderRadius: "22px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "8px",
      paddingBottom: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  gpzjgt: {
    variant: "overline",
    text: "Status",
    gutterBottom: false,
    noWrap: false,
    css: { width: "40%" },
  },
  voojwx: {
    variant: "overline",
    text: "Dernière mise à jour",
    gutterBottom: false,
    noWrap: false,
    css: { width: "30%", textAlign: "right", flexGrow: "1" },
  },
  mhitik: {
    variant: "overline",
    text: "Status",
    gutterBottom: false,
    noWrap: false,
    css: { width: "40%" },
  },
  cvhhdj: {
    variant: "overline",
    text: "Dernière mise à jour",
    gutterBottom: false,
    noWrap: false,
    css: { width: "30%", textAlign: "right", flexGrow: "1" },
  },
  zdltvi: {
    tag: "div",
    css: {
      width: "180px",
      textAlign: "right",
      gap: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  luewid: {},
};

/** DEFAULT PROPS End */
