import React, { useCallback, useMemo } from "react";
import { Tab, Tabs, styled } from "@mui/material";
import { useNavigate } from "@repleek/mui";
import { useLocation } from "react-router-dom";

type Props = {
  menu: {
    label: string;
    href: string;
  }[];
};
type StyledTabProps = {
  label: string;
};
type StyledTabsProps = {
  children?: React.ReactNode;
  value?: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "100% !important",
    borderRadius: "32px",
    overflow: "hidden",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  borderRadius: "32px",
  fontFamily: "'Unbounded',cursive",
  color: "black",
  zIndex: 1,
  "&.Mui-selected": {
    color: "black",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const Menu: React.FC<Props> = ({ menu }) => {
  const push = useNavigate();
  const { pathname } = useLocation();

  const value = useMemo(() => {
    const index = menu.findIndex(({ href }) => href === pathname);
    if (index >= 0) return index;
    return undefined;
  }, [menu, pathname]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, index: number) => {
      push.navigate?.(menu[index].href);
    },
    [menu, push]
  );

  if (!menu.length) return null;

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {menu.map(({ label, href }) => (
        <StyledTab label={label} key={href} />
      ))}
    </StyledTabs>
  );
};

export default Menu;
