/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type ListReportProps = {
  reporting: PaperProps,
  newReporting: ButtonProps & {},
  pagination: BoxProps,
};

type DefaultProps = {
  ROOT: ContainerProps,
  ieqlot: TypographyProps,
  qobfvg: PaperProps,
  riorps: BoxProps,
  vbrcvp: ButtonProps,
  jwaapv: IconProps,
  nxleze: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const ListReport: React.FC<ListReportProps> = (props) => {
  const { reporting, newReporting, pagination } = props;
  const { ROOT, ieqlot, qobfvg, riorps, vbrcvp, jwaapv, nxleze } = defaultProps;

  return (
    <Container {...ROOT}>
      <Box {...riorps}>
        <Typography {...ieqlot} />
        <Button
          {...vbrcvp}
          IconStart={<Icon {...jwaapv} />}
          {...newReporting}
        />
      </Box>
      <Paper {...qobfvg} {...reporting} />
      <Box {...nxleze} {...pagination} />
    </Container>
  );
};

export default ListReport;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: { paddingTop: "32px" },
  },
  ieqlot: {
    variant: "h2",
    text: "Reporting",
    gutterBottom: false,
    noWrap: false,
    css: {
      fontSize: "32px",
      fontWeight: 700,
      marginBottom: "16px",
      textTransform: "uppercase",
    },
  },
  qobfvg: {
    square: true,
    variant: "elevation",
    elevation: 0,
    css: {
      padding: "32px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000",
      borderRadius: "22px",
      marginTop: "16px",
      overflow: "hidden",
      overflowY: "auto",
      minHeight: "45vh",
    },
  },
  riorps: {
    tag: "div",
    css: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  vbrcvp: {
    text: "GENERER",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "medium",
    variant: "contained",
    color: "primary",
    iconStart: true,
    iconEnd: false,
    href: "/new-reporting",
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  jwaapv: {
    color: "inherit",
    icon: { name: "IoMdAdd", library: "io" },
    fontSize: "inherit",
    hidden: false,
  },
  nxleze: {
    tag: "div",
    css: { marginTop: "16px", display: "flex", justifyContent: "center" },
  },
};

/** DEFAULT PROPS End */
