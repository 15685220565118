/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Container,
  ContainerProps,
  Divider,
  DividerProps,
  Form,
  FormProps,
  Fragment,
  FragmentProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type RepportingFormProps = {
  form: FormProps,
  title: TypographyProps & {},
  facebookTitle: DividerProps,
  tiktokTitle: DividerProps,
  instagramTitle: DividerProps,
  linkedinTitle: DividerProps,
  facebook: BoxProps,
  instagram: BoxProps,
  tiktok: BoxProps,
  linkedin: BoxProps,
  date: FragmentProps,
};

type DefaultProps = {
  ROOT: FormProps,
  sbhyfs: TextFieldProps,
  euqrvz: ButtonProps,
  zcatrb: PaperProps,
  gippfb: BoxProps,
  opjblc: TypographyProps,
  ouaxsi: ContainerProps,
  ijhlfn: TextFieldProps,
  kjcgea: BoxProps,
  eruxqg: DividerProps,
  ctdtdw: ChipProps,
  xywrvk: DividerProps,
  gxefzu: DividerProps,
  mlocdr: DividerProps,
  jvtuvi: BoxProps,
  lixrpb: BoxProps,
  fnandm: BoxProps,
  bvuwmk: BoxProps,
  pkwnvc: FragmentProps,
  vajfgn: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const RepportingForm: React.FC<RepportingFormProps> = (props) => {
  const {
    form,
    title,
    facebookTitle,
    tiktokTitle,
    instagramTitle,
    linkedinTitle,
    facebook,
    instagram,
    tiktok,
    linkedin,
    date,
  } = props;
  const {
    ROOT,
    sbhyfs,
    euqrvz,
    zcatrb,
    gippfb,
    opjblc,
    ouaxsi,
    ijhlfn,
    kjcgea,
    eruxqg,
    ctdtdw,
    xywrvk,
    gxefzu,
    mlocdr,
    jvtuvi,
    lixrpb,
    fnandm,
    bvuwmk,
    pkwnvc,
    vajfgn,
  } = defaultProps;

  return (
    <Form {...ROOT} {...form}>
      <Container {...ouaxsi}>
        <Paper {...zcatrb}>
          <Typography {...opjblc} {...title} />
          <Box {...gippfb}>
            <Box {...kjcgea}>
              <TextField {...sbhyfs} />
              <Fragment {...pkwnvc} {...date} />
            </Box>
            <TextField {...ijhlfn} />
          </Box>
          <Divider {...eruxqg} Chip={<Chip {...ctdtdw} />} {...facebookTitle} />
          <Box {...jvtuvi} {...facebook} />
          <Divider {...gxefzu} {...instagramTitle} />
          <Box {...lixrpb} {...instagram} />
          <Divider {...xywrvk} {...tiktokTitle} />
          <Box {...fnandm} {...tiktok} />
          <Divider {...mlocdr} {...linkedinTitle} />
          <Box {...bvuwmk} {...linkedin} />
          <Box {...vajfgn}>
            <Button {...euqrvz} />
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default RepportingForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { mode: "onSubmit" },
  sbhyfs: {
    inputName: "name",
    label: "Nom du reporting",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: false,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "medium",
    css: { flexGrow: "1" },
  },
  euqrvz: {
    text: "Enregistrer",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
  },
  zcatrb: {
    square: false,
    variant: "outlined",
    elevation: 0,
    css: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "26px",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
      paddingLeft: "42px",
      paddingRight: "42px",
      paddingTop: "42px",
    },
  },
  gippfb: {
    tag: "div",
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  opjblc: {
    variant: "h1",
    text: "Nouveau reporting",
    gutterBottom: false,
    noWrap: false,
    css: { marginBottom: "26px", textTransform: "uppercase", fontSize: "28px" },
  },
  ouaxsi: { maxWidth: "md", disableGutters: false, fixed: false },
  ijhlfn: {
    inputName: "comment",
    label: "Commentaire général",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: "6",
    minRows: 1,
    rows: "2",
    multiline: true,
    readOnly: false,
    required: false,
    size: "medium",
  },
  kjcgea: {
    tag: "div",
    css: {
      gap: "16px",
      marginBottom: "16px",
      display: "flex",
      alignItems: "center",
    },
  },
  eruxqg: {
    label: "Facebook Reporting",
    chip: false,
    flexItem: false,
    light: false,
    orientation: "horizontal",
    textAlign: "left",
    variant: "fullWidth",
    css: {
      fontFamily: "'Unbounded',cursive",
      fontSize: "18px",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  ctdtdw: {
    label: "Test",
    avatar: false,
    icon: false,
    clickable: false,
    color: "default",
    variant: "filled",
  },
  xywrvk: {
    label: "Tiktok Reporting",
    chip: false,
    flexItem: false,
    light: false,
    orientation: "horizontal",
    textAlign: "left",
    variant: "fullWidth",
    css: {
      fontFamily: "'Unbounded',cursive",
      fontSize: "18px",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  gxefzu: {
    label: "Instagram Reporting",
    chip: false,
    flexItem: false,
    light: false,
    orientation: "horizontal",
    textAlign: "left",
    variant: "fullWidth",
    css: {
      fontFamily: "'Unbounded',cursive",
      fontSize: "18px",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  mlocdr: {
    label: "LinkedIn Reporting",
    chip: false,
    flexItem: false,
    light: false,
    orientation: "horizontal",
    textAlign: "left",
    variant: "fullWidth",
    css: {
      fontFamily: "'Unbounded',cursive",
      fontSize: "18px",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  jvtuvi: { tag: "div", css: { padding: "16px" } },
  lixrpb: { tag: "div", css: { padding: "16px" } },
  fnandm: { tag: "div", css: { padding: "16px" } },
  bvuwmk: { tag: "div", css: { padding: "16px" } },
  pkwnvc: {},
  vajfgn: {
    tag: "div",
    css: {
      backgroundColor: "rgba(255,255,255, 1)",
      zIndex: "100",
      textAlign: "right",
      position: "sticky",
      bottom: "0px",
      paddingTop: "16px",
      paddingBottom: "26px",
    },
  },
};

/** DEFAULT PROPS End */
