import { MobileDatePicker, MobileDatePickerProps } from "@mui/x-date-pickers";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  defaultPeriod?: string | Date;
};

const RangeMounthPicker: React.FC<Props> = (props) => {
  const { defaultPeriod } = props;
  const { control, watch } = useFormContext();

  const from = watch("from");
  const to = watch("to");

  const slotProps: MobileDatePickerProps<any>["slotProps"] = {
    calendarHeader: {
      slotProps: {
        nextIconButton: {
          color: "secondary",
          className: "no-shadow no-borders",
        },
        previousIconButton: {
          color: "secondary",
          className: "no-shadow no-borders",
        },
        switchViewButton: {
          color: "secondary",
          className: "no-shadow no-borders",
        },
      },
    },
    textField: {
      size: "small",
      sx: { width: "130px" },
      color: "secondary",
    },
  };
  return (
    <>
      <Controller
        control={control}
        name="from"
        render={({
          field: { ref, onBlur, name, onChange, value = null, ...field },
        }) => (
          <MobileDatePicker
            inputRef={ref}
            value={value}
            onChange={onChange}
            slotProps={slotProps}
            localeText={{ cancelButtonLabel: "Annuler" }}
            views={["month", "year"]}
            disableFuture
            disabled={!!defaultPeriod}
            monthsPerRow={4}
            maxDate={to}
            format="MMM YYYY"
            label="Début"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="to"
        render={({
          field: { ref, onBlur, name, onChange, value = null, ...field },
        }) => (
          <MobileDatePicker
            inputRef={ref}
            value={value}
            onChange={onChange}
            slotProps={slotProps}
            localeText={{ cancelButtonLabel: "Annuler" }}
            views={["month", "year"]}
            disableFuture
            disabled={!!defaultPeriod}
            monthsPerRow={4}
            minDate={from}
            format="MMM YYYY"
            label="Fin"
            {...field}
          />
        )}
      />
    </>
  );
};

export default RangeMounthPicker;
