import React, { useCallback, useMemo } from "react";
import { ResetPasswordForm } from "@/components";
import Layout from "@/components/layout";
import { useStrapi } from "@/providers/StrapiProvider";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { StrapiResetPasswordData } from "@/modules/strapi-sdk";

const ResetPassword: React.FC = () => {
  const [strapi] = useStrapi();

  const { search } = useLocation();
  const { token, name } = useMemo(
    () => ({ ...queryString.parse(search) }),
    [search]
  );

  const onResetPass = useCallback(
    async ({ email }: { email: string }) => {
      const success = await strapi?.forgotPassword({ email });
      if (success) {
        return toast("Email de réinitialisation envoyé avec succès");
      }
      toast(
        "Une erreur est survenue lors de l'envoi de l'email de réinitialisation"
      );
    },
    [strapi]
  );

  const onChangePass = useCallback(
    async ({
      code,
      password,
      passwordConfirmation,
    }: StrapiResetPasswordData) => {
      await strapi?.resetPassword({
        code,
        password,
        passwordConfirmation,
      });
    },
    [strapi]
  );

  const content = useMemo(() => {
    if (token) {
      return {
        title: (name as string) || "Réinitialisation",
        info: "Veuillez choisir un mot de passe sécurisé. Il doit contenir au moins 6 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.",
        onSubmit: onChangePass,
        defaultValues: {
          code: token,
        },
      };
    }
    return {
      title: "Réinitialisation",
      info: "Pour réinitialiser votre mot de passe, veuillez entrer votre adresse e-mail.",
      onSubmit: onResetPass,
      defaultValues: { email: "" },
    };
  }, [name, onChangePass, onResetPass, token]);

  return (
    <Layout title="Pistache - connexion" mode="LOGGED_OUT" redirect="/">
      <ResetPasswordForm
        form={{
          onSubmit: content.onSubmit,
          defaultValues: content.defaultValues,
        }}
        title={{ text: content.title }}
        info={{ text: content.info }}
        resetPass={{ hidden: !!token }}
        changePass={{ hidden: !token }}
      />
    </Layout>
  );
};

export default ResetPassword;
