/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Fragment,
  FragmentProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type ReportingViewHeaderProps = {
  action: ButtonProps & {},
  form: FormProps,
  dateRange: FragmentProps,
  logo: AvatarProps,
  name: TypographyProps & {},
  email: TypographyProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  lxfhab: ButtonProps,
  fjtctb: FormProps,
  bpnorg: FragmentProps,
  fbthwq: BoxProps,
  koltrj: AvatarProps,
  sqgpfz: BoxProps,
  nazpxq: TypographyProps,
  stihqf: TypographyProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const ReportingViewHeader: React.FC<ReportingViewHeaderProps> = (props) => {
  const { action, form, dateRange, logo, name, email } = props;
  const {
    ROOT,
    lxfhab,
    fjtctb,
    bpnorg,
    fbthwq,
    koltrj,
    sqgpfz,
    nazpxq,
    stihqf,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <Form {...fjtctb} {...form}>
        <Box {...fbthwq}>
          <Avatar {...koltrj} {...logo} />
          <Box {...sqgpfz}>
            <Typography {...nazpxq} {...name} />
            <Typography {...stihqf} {...email} />
          </Box>
        </Box>
        <Fragment {...bpnorg} {...dateRange} />
        <Button {...lxfhab} {...action} />
      </Form>
    </Container>
  );
};

export default ReportingViewHeader;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "lg",
    disableGutters: false,
    fixed: false,
    css: {
      padding: "32px",
      backgroundColor: "rgba(255,255,255, 1)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "16px",
      marginBottom: "16px",
    },
  },
  lxfhab: {
    text: "Filtrer",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    variant: "contained",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  fjtctb: {
    mode: "onSubmit",
    css: {
      gap: "12px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  bpnorg: {},
  fbthwq: {
    tag: "div",
    css: { flexGrow: "1", gap: "12px", display: "flex", alignItems: "center" },
  },
  koltrj: {
    variant: "rounded",
    component: "div",
    css: { width: "64px", height: "64px" },
  },
  sqgpfz: { tag: "div", css: { flexGrow: "1" } },
  nazpxq: {
    variant: "h4",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
  },
  stihqf: {
    variant: "body1",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
  },
};

/** DEFAULT PROPS End */
