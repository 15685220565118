/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Fragment,
  FragmentProps,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type LoadingProps = Partial<BoxProps> & {
  logo: ImageProps,
  loader: FragmentProps,
};

type DefaultProps = {
  ROOT: BoxProps,
  mhhadx: BoxProps,
  pjcqrf: ImageProps,
  xscsea: FragmentProps,
  gviuzl: TypographyProps,
  cqptaw: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const Loading: React.FC<LoadingProps> = (props) => {
  const { logo, loader, ...wrapperProps } = props;
  const { ROOT, mhhadx, pjcqrf, xscsea, gviuzl, cqptaw } = defaultProps;

  return (
    <Box {...ROOT} {...wrapperProps}>
      <Box {...cqptaw}>
        <Box {...mhhadx}>
          <Fragment {...xscsea} {...loader} />
          <Image {...pjcqrf} {...logo} />
        </Box>
        <Typography {...gviuzl} />
      </Box>
    </Box>
  );
};

export default Loading;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    tag: "div",
    css: {
      minHeight: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  mhhadx: { tag: "div", css: { position: "relative" } },
  pjcqrf: {
    alt: "Image description for better SEO",
    src: "/images/logo-mini.png",
    dynamic: true,
  },
  xscsea: {},
  gviuzl: {
    variant: "h4",
    text: "Loading ...",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "22px", fontFamily: "Unbounded" },
  },
  cqptaw: { tag: "div", css: { textAlign: "center" } },
};

/** DEFAULT PROPS End */
