/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type EmailResumeProps = {
  edit: ButtonProps & {},
  validate: ButtonProps & {},
  email: TypographyProps & {
    email?: string,
  },
  subject: TypographyProps & {
    subject?: string,
  },
  title: TypographyProps & {},
  message: TypographyProps & {},
  buttonName: TypographyProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  wsdllg: BoxProps,
  jbywrx: ButtonProps,
  mfjnpr: ButtonProps,
  vrrtqd: BoxProps,
  wvrsvp: BoxProps,
  chmcpf: BoxProps,
  kommdb: TypographyProps,
  tkxhxv: TypographyProps,
  jodqdu: TypographyProps,
  xuptag: TypographyProps,
  lqlksj: BoxProps,
  fppdfz: ImageProps,
  yutnhd: TypographyProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const EmailResume: React.FC<EmailResumeProps> = (props) => {
  const { edit, validate, email, subject, title, message, buttonName } = props;
  const {
    ROOT,
    wsdllg,
    jbywrx,
    mfjnpr,
    vrrtqd,
    wvrsvp,
    chmcpf,
    kommdb,
    tkxhxv,
    jodqdu,
    xuptag,
    lqlksj,
    fppdfz,
    yutnhd,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <Box {...vrrtqd}>
        <Box {...wvrsvp}>
          <Typography {...kommdb} {...email} />
          <Typography {...tkxhxv} {...subject} />
          <Box {...chmcpf}>
            <Typography {...jodqdu} {...title} />
            <Typography {...xuptag} {...message} />
            <Typography {...yutnhd} {...buttonName} />
            <Box {...lqlksj}>
              <Image {...fppdfz} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box {...wsdllg}>
        <Button {...jbywrx} {...edit} />
        <Button {...mfjnpr} {...validate} />
      </Box>
    </Container>
  );
};

export default EmailResume;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { maxWidth: "sm", disableGutters: false, fixed: false },
  wsdllg: {
    tag: "div",
    css: { display: "flex", justifyContent: "space-between" },
  },
  jbywrx: {
    text: "Modifier",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    color: "secondary",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  mfjnpr: {
    text: "Envoyer",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  vrrtqd: {
    tag: "div",
    css: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#DDDBDB",
      boxShadow:
        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px , rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      overflow: "hidden",
      borderRadius: "8px",
      marginBottom: "12px",
    },
  },
  wvrsvp: { tag: "div" },
  chmcpf: {
    tag: "div",
    css: {
      padding: "24px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  kommdb: {
    variant: "body2",
    text: "À <b>{{email}}</b>",
    gutterBottom: false,
    noWrap: false,
    css: {
      padding: "8px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "#DDDBDB",
    },
  },
  tkxhxv: {
    variant: "body2",
    text: "Objet <b>{{subject}}</b>",
    gutterBottom: false,
    noWrap: false,
    css: {
      padding: "8px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "#DDDBDB",
    },
  },
  jodqdu: {
    variant: "h3",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "18px" },
  },
  xuptag: {
    variant: "body2",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "16px", marginTop: "8px", marginBottom: "16px" },
  },
  lqlksj: { tag: "div", css: { marginTop: "12px" } },
  fppdfz: {
    alt: "Image description for better SEO",
    src: "/images/logo.png",
    dynamic: true,
    css: { width: "100%", maxWidth: "180px" },
  },
  yutnhd: {
    variant: "h1",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: {
      backgroundColor: "rgba(0, 253, 122, 1)",
      fontSize: "14px",
      fontWeight: 500,
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "8px",
      marginBottom: "16px",
      borderRadius: "32px",
      textTransform: "uppercase",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      cursor: "pointer",
    },
  },
};

/** DEFAULT PROPS End */
