/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Icon,
  IconProps,
  InputBase,
  InputBaseProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type SocialPageSelectProps = {
  search: InputBaseProps,
  list: BoxProps,
  message: TypographyProps & {},
  logout: ButtonProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  xbxquk: FormProps,
  vzxxmw: InputBaseProps,
  tmbpen: BoxProps,
  wajisk: TypographyProps,
  ihchkb: ButtonProps,
  tppquw: IconProps,
  tirnlb: ContainerProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const SocialPageSelect: React.FC<SocialPageSelectProps> = (props) => {
  const { search, list, message, logout } = props;
  const { ROOT, xbxquk, vzxxmw, tmbpen, wajisk, ihchkb, tppquw, tirnlb } =
    defaultProps;

  return (
    <Container {...ROOT}>
      <Container {...tirnlb}>
        <Form {...xbxquk}>
          <InputBase {...vzxxmw} {...search} />
        </Form>
        <Button {...ihchkb} IconEnd={<Icon {...tppquw} />} {...logout} />
      </Container>
      <Typography {...wajisk} {...message} />
      <Box {...tmbpen} {...list} />
    </Container>
  );
};

export default SocialPageSelect;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "sm",
    disableGutters: false,
    fixed: false,
    css: { minWidth: "540px", paddingTop: "8px", paddingBottom: "24px" },
  },
  xbxquk: {
    mode: "onChange",
    css: {
      padding: "4px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#333232",
      gap: "8px",
      flexGrow: "1",
      borderRadius: "25px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  vzxxmw: {
    inputName: "search",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    placeholder: "Recherche",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "medium",
    css: { paddingLeft: "16px" },
  },
  tmbpen: {
    tag: "div",
    css: { padding: "12px", maxHeight: "50vh", overflowY: "auto" },
  },
  wajisk: {
    variant: "body2",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { textAlign: "center", paddingTop: "16px", paddingBottom: "16px" },
  },
  ihchkb: {
    text: "log out",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    color: "secondary",
    iconStart: false,
    iconEnd: true,
    css: {
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
  tppquw: {
    color: "inherit",
    icon: { name: "FiLogOut", library: "fi" },
    fontSize: "inherit",
    hidden: false,
  },
  tirnlb: {
    maxWidth: "xs",
    disableGutters: false,
    fixed: false,
    css: { gap: "8px", display: "flex", alignItems: "center" },
  },
};

/** DEFAULT PROPS End */
