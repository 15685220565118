/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  TextField,
  TextFieldProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type EmailFormProps = {
  email: TextFieldProps,
  back: ButtonProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  tpqvol: TextFieldProps,
  iaznzs: BoxProps,
  kayphw: BoxProps,
  eabujw: ButtonProps,
  skspsj: ButtonProps,
  wadvmw: TextFieldProps,
  zagfon: TextFieldProps,
  tfrpmy: TextFieldProps,
  hfbkvs: TextFieldProps,
  trfmfv: TextFieldProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const EmailForm: React.FC<EmailFormProps> = (props) => {
  const { email, back } = props;
  const {
    ROOT,
    tpqvol,
    iaznzs,
    kayphw,
    eabujw,
    skspsj,
    wadvmw,
    zagfon,
    tfrpmy,
    hfbkvs,
    trfmfv,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <TextField {...tpqvol} {...email} />
      <TextField {...hfbkvs} />
      <TextField {...trfmfv} />
      <TextField {...tfrpmy} />
      <Box {...iaznzs}>
        <TextField {...wadvmw} />
        <TextField {...zagfon} />
      </Box>
      <Box {...kayphw}>
        <Button {...eabujw} {...back} />
        <Button {...skspsj} />
      </Box>
    </Container>
  );
};

export default EmailForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { maxWidth: "xs", disableGutters: false, fixed: false },
  tpqvol: {
    inputName: "email",
    label: "Destinataire",
    variant: "outlined",
    color: "primary",
    type: "email",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
    css: { marginBottom: "12px" },
  },
  iaznzs: { tag: "div", css: { gap: "8px", display: "flex" } },
  kayphw: {
    tag: "div",
    css: {
      marginTop: "12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  eabujw: {
    text: "Annuler",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    color: "secondary",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  skspsj: {
    text: "Valider",
    type: "submit",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  },
  wadvmw: {
    inputName: "button_name",
    label: "Ancre",
    variant: "outlined",
    color: "secondary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
    css: { width: "280px", marginBottom: "12px" },
  },
  zagfon: {
    inputName: "link",
    label: "Lien",
    variant: "outlined",
    color: "secondary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
    css: { marginBottom: "12px" },
  },
  tfrpmy: {
    inputName: "message",
    label: "Message",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: "5",
    minRows: "3",
    rows: "3",
    multiline: true,
    readOnly: false,
    required: false,
    size: "small",
    css: { marginBottom: "12px" },
  },
  hfbkvs: {
    inputName: "subject",
    label: "Objet",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: true,
    size: "small",
    css: { marginBottom: "12px" },
  },
  trfmfv: {
    inputName: "title",
    label: "Titre",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "small",
    css: { marginBottom: "12px" },
  },
};

/** DEFAULT PROPS End */
