export function decodeHtml(html?: string) {
  if (!html) return;
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
export const ssoOauth = {
  tiktok: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/tiktok`,
    title: "Tiktok",
  },
  facebook: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/facebook`,
    title: "",
  },
  instagram: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/facebook`,
    title: "",
  },
  linkedin: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/linkedin`,
    title: "",
  },
};
