/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import { Box, BoxProps, TextField, TextFieldProps } from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type StatisticFieldsProps = {
  followers: TextFieldProps,
  posts: TextFieldProps,
  impressions: TextFieldProps,
  interactions: TextFieldProps,
  comment: TextFieldProps,
};

type DefaultProps = {
  ROOT: BoxProps,
  rjhanr: TextFieldProps,
  fokbtm: TextFieldProps,
  kdnpus: TextFieldProps,
  mtwszo: TextFieldProps,
  akeyyb: BoxProps,
  reoqyn: TextFieldProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const StatisticFields: React.FC<StatisticFieldsProps> = (props) => {
  const { followers, posts, impressions, interactions, comment } = props;
  const { ROOT, rjhanr, fokbtm, kdnpus, mtwszo, akeyyb, reoqyn } = defaultProps;

  return (
    <Box {...ROOT}>
      <Box {...akeyyb}>
        <TextField {...rjhanr} {...followers} />
        <TextField {...mtwszo} {...interactions} />
        <TextField {...kdnpus} {...impressions} />
        <TextField {...fokbtm} {...posts} />
      </Box>
      <TextField {...reoqyn} {...comment} />
    </Box>
  );
};

export default StatisticFields;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: { tag: "div" },
  rjhanr: {
    inputName: "input",
    label: "Nb de followers",
    variant: "outlined",
    color: "primary",
    type: "number",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: false,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "medium",
  },
  fokbtm: {
    inputName: "input",
    label: "Nb de posts",
    variant: "outlined",
    color: "primary",
    type: "number",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: false,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "medium",
  },
  kdnpus: {
    inputName: "input",
    label: "Nb d'impressions",
    variant: "outlined",
    color: "primary",
    type: "number",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: false,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "medium",
  },
  mtwszo: {
    inputName: "input",
    label: "Nb d'intéractions",
    variant: "outlined",
    color: "primary",
    type: "number",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: false,
    maxRows: 1,
    minRows: 1,
    rows: 1,
    multiline: false,
    readOnly: false,
    required: false,
    size: "medium",
  },
  akeyyb: {
    tag: "div",
    css: {
      gap: "16px",
      marginBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  reoqyn: {
    inputName: "Input name",
    label: "Commentaire",
    variant: "outlined",
    color: "primary",
    type: "text",
    "aria-label": "Input Base Mui",
    autoFocus: false,
    disabled: false,
    fullWidth: true,
    maxRows: "3",
    minRows: "3",
    rows: "2",
    multiline: true,
    readOnly: false,
    required: false,
    size: "medium",
  },
};

/** DEFAULT PROPS End */
