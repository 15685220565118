import React, { useCallback, useEffect, useMemo } from "react";
import RangeMounthPicker from "@/components/fields/RangeMounthPicker";
import dayjs, { Dayjs } from "dayjs";
import ReportingViewHeader from "./ReportingViewHeader";
import { accountSelector } from "@/store/accounts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import RepotingTab, { Tab } from "@/components/features/reports/ReportingTab";
import ReportingView from "./ReportingView";
import { Icon, IconProps } from "@repleek/mui";
import { Box, Container, Typography } from "@mui/material";
import {
  insightsByType,
  reportingByRangeQuery,
  reportingSelector,
} from "@/store/reporting";
import NotFound from "@/components/layout/NotFound";
import BreadCrumb from "@/components/layout/BreadCrumb";
import Home from "@mui/icons-material/Home";
import { BusinessCenter, PlaylistAddCheck } from "@mui/icons-material";
import BuildingDashboard from "@/components/layout/BuildingDashboard";
import { useSocialAccess } from "@/hooks/account";

type Props = {
  accountId: string;
  id?: string;
};
type Filter = {
  from?: Dayjs;
  to?: Dayjs;
};

const ReportingComponent: React.FC<Props> = ({ accountId, id }) => {
  const account = useRecoilValue(accountSelector(accountId));
  const reporting = useRecoilValue(reportingSelector({ accountId, id }));
  const setRepotingRange = useSetRecoilState(reportingByRangeQuery(accountId));
  const data = useRecoilValue(insightsByType({ type: "global", accountId }));
  const access = useSocialAccess(account?.configuration);

  const defaultValues = useMemo(
    () => ({
      from: dayjs(),
      to: dayjs(),
    }),
    []
  );

  const onFilter = useCallback(
    ({ from, to }: Filter) => {
      if (from && to) {
        if (from?.isSame(to, "months")) {
          // TODO à verifier
          return setRepotingRange({
            from: from.subtract(2, "M").toDate(),
            to: to.toDate(),
          });
        }
        return setRepotingRange({
          from: from.toDate(),
          to: to.toDate(),
        });
      }
    },
    [setRepotingRange]
  );

  const tabs = useMemo<Tab[]>(() => {
    const res = [
      {
        id: "global",
        label: <Label label="Global" type="global" />,
        content: (
          <ReportingView type={"global"} accountId={accountId.toString()} />
        ),
      },
    ];

    if (access.facebook)
      res.push({
        id: "facebook",
        label: <Label label="Facebook" type="facebook" />,
        content: (
          <ReportingView type={"facebook"} accountId={accountId.toString()} />
        ),
      });

    if (access.instagram)
      res.push({
        id: "instagram",
        label: <Label label="Instagram" type="instagram" />,
        content: (
          <ReportingView type={"instagram"} accountId={accountId.toString()} />
        ),
      });

    if (access.tiktok)
      res.push({
        id: "tiktok",
        label: <Label label="TikTok" type="tiktok" />,
        content: (
          <ReportingView type={"tiktok"} accountId={accountId.toString()} />
        ),
      });

    if (access.linkedin)
      res.push({
        id: "linkedIn",
        label: <Label label="LinkedIn" type="linkedIn" />,
        content: (
          <ReportingView type={"linkedin"} accountId={accountId.toString()} />
        ),
      });

    return res;
  }, [
    access.facebook,
    access.instagram,
    access.linkedin,
    access.tiktok,
    accountId,
  ]);

  useEffect(() => {
    if (reporting?.date) {
      const date = dayjs(reporting.date);
      setRepotingRange({
        from: date.subtract(2, "M").toDate(),
        to: date.toDate(),
      });
    } else {
      onFilter(defaultValues);
    }
  }, [defaultValues, onFilter, reporting, setRepotingRange]);

  if (id && !reporting) return <NotFound />;

  return (
    <>
      {id && (
        <Container
          maxWidth="lg"
          sx={{
            background: "white",
            padding: "10px",
            borderRadius: "26px",
            border: "1px solid black",
            mb: "18px",
          }}>
          <BreadCrumb
            breadCrumbs={[
              { label: "Comptes", icon: Home, href: "/" },
              {
                label: account?.name || "Compte",
                icon: BusinessCenter,
                href: `/account/${accountId}`,
              },
              { label: reporting?.name || "Reporting", icon: PlaylistAddCheck },
            ]}
          />
        </Container>
      )}

      <ReportingViewHeader
        dateRange={{
          children: <RangeMounthPicker />,
          hidden: !!reporting,
        }}
        form={{ onSubmit: onFilter, defaultValues }}
        logo={{
          src:
            account?.facebook_account?.picture || "/images/logo-pistache.jpg",
        }}
        name={{ text: account?.name }}
        email={{ text: account?.email }}
        action={
          !!reporting
            ? {
                text: "Modifier",
                type: "button",
                href: `/${accountId}/edit-reporting/${id}`,
              }
            : {}
        }
      />

      {data ? (
        <RepotingTab tabs={tabs} defaultTab={"global"} />
      ) : (
        <BuildingDashboard />
      )}
    </>
  );
};

export default ReportingComponent;

const Label: React.FC<{ label: string; type: string }> = (props) => {
  const { label, type } = props;
  const oauth: Record<string, IconProps> = useMemo(
    () => ({
      global: {
        icon: { library: "io5", name: "IoBarChartOutline" },
        style: { color: "#FF4DF7" },
      },
      tiktok: {
        icon: { library: "si", name: "SiTiktok" },
        style: { color: "#000000" },
      },
      facebook: {
        icon: { library: "si", name: "SiFacebook" },
        style: { color: "#1877F2" },
      },
      instagram: {
        icon: { library: "sl", name: "SlSocialInstagram" },
        style: { color: "#833AB4" },
      },
      linkedIn: {
        icon: { library: "si", name: "SiLinkedin" },
        style: { color: "#0A66C2" },
      },
    }),
    []
  );

  const icon = useMemo(() => oauth[type], [oauth, type]);

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Icon {...icon} css={{ fontSize: "18px" }} />
      <Typography variant="h3" sx={{ fontSize: "14px" }}>
        {label}
      </Typography>
    </Box>
  );
};
