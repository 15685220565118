/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type SocialItemProps = {
  avatar: AvatarProps,
  link: ButtonProps & {},
  name: TypographyProps & {},
  instagram: TypographyProps & {},
};

type DefaultProps = {
  ROOT: ContainerProps,
  klsbmi: AvatarProps,
  hvyuef: BoxProps,
  acgjyk: ButtonProps,
  buquys: TypographyProps,
  pfdfzh: TypographyProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const SocialItem: React.FC<SocialItemProps> = (props) => {
  const { avatar, link, name, instagram } = props;
  const { ROOT, klsbmi, hvyuef, acgjyk, buquys, pfdfzh } = defaultProps;

  return (
    <Container {...ROOT}>
      <Avatar {...klsbmi} {...avatar} />
      <Box {...hvyuef}>
        <Typography {...buquys} {...name} />
        <Typography {...pfdfzh} {...instagram} />
      </Box>
      <Button {...acgjyk} {...link} />
    </Container>
  );
};

export default SocialItem;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "sm",
    disableGutters: false,
    fixed: false,
    css: {
      padding: "8px",
      boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
      gap: "8px",
      borderRadius: "16px",
      marginBottom: "12px",
      display: "flex",
      alignItems: "center",
    },
  },
  klsbmi: {
    variant: "circular",
    component: "div",
    css: { width: "54px", height: "54px" },
  },
  hvyuef: { tag: "div", css: { flexGrow: "1" } },
  acgjyk: {
    text: "Link",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
  buquys: {
    variant: "h3",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "16px" },
  },
  pfdfzh: {
    variant: "overline",
    text: "text",
    gutterBottom: false,
    noWrap: false,
    css: { marginTop: "8px", lineHeight: "16px" },
  },
};

/** DEFAULT PROPS End */
