/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type SocialSelectProps = {
  icon: IconProps,
  title: TypographyProps & {},
  cancel: ButtonProps & {},
  message: TypographyProps & {
    type?: string,
  },
  confirm: BoxProps,
};

type DefaultProps = {
  ROOT: ContainerProps,
  spmjdk: BoxProps,
  fwxeck: BoxProps,
  ajjbgf: IconProps,
  rbalud: TypographyProps,
  lmhboq: ButtonProps,
  wcvjvv: BoxProps,
  hhdusa: TypographyProps,
  sdhmtj: BoxProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const SocialSelect: React.FC<SocialSelectProps> = (props) => {
  const { icon, title, cancel, message, confirm } = props;
  const {
    ROOT,
    spmjdk,
    fwxeck,
    ajjbgf,
    rbalud,
    lmhboq,
    wcvjvv,
    hhdusa,
    sdhmtj,
  } = defaultProps;

  return (
    <Container {...ROOT}>
      <Box {...fwxeck}>
        <Icon {...ajjbgf} {...icon} />
        <Box {...wcvjvv}>
          <Typography {...rbalud} {...title} />
        </Box>
      </Box>
      <Typography {...hhdusa} {...message} />
      <Box {...spmjdk}>
        <Button {...lmhboq} {...cancel} />
        <Box {...sdhmtj} {...confirm} />
      </Box>
    </Container>
  );
};

export default SocialSelect;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "xs",
    disableGutters: false,
    fixed: false,
    css: {
      padding: "16px",
      width: "560px",
      backgroundColor: "rgba(255,255,255, 1)",
    },
  },
  spmjdk: {
    tag: "div",
    css: {
      marginTop: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  fwxeck: { tag: "div", css: { display: "flex", alignItems: "center" } },
  ajjbgf: {
    color: "inherit",
    icon: { name: "BsQuestionCircle", library: "bs" },
    fontSize: "inherit",
    css: { fontSize: "54px" },
  },
  rbalud: {
    variant: "h1",
    text: "Mon titre ici",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "22px" },
  },
  lmhboq: {
    text: "Annuler",
    type: "button",
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    fullWidth: false,
    size: "small",
    variant: "contained",
    color: "secondary",
    iconStart: false,
    iconEnd: false,
    css: {
      paddingLeft: "14px",
      paddingRight: "14px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  wcvjvv: { tag: "div", css: { flexGrow: "1", paddingLeft: "14px" } },
  hhdusa: {
    variant: "caption",
    text: "Pour avoir la possibilité de sélectionner une page, il est nécessaire de se connecter à un compte <b><u>{{type}}</u></b>.",
    gutterBottom: false,
    noWrap: false,
    css: { fontSize: "14px", paddingTop: "8px" },
  },
  sdhmtj: { tag: "div" },
};

/** DEFAULT PROPS End */
