/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Container,
  ContainerProps,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type BuildingDashboardProps = Partial<ContainerProps> & {};

type DefaultProps = {
  ROOT: ContainerProps,
  nrrvre: ImageProps,
  arufhq: TypographyProps,
  jagbhd: ContainerProps,
  ebaqji: TypographyProps,
  mcghoj: ContainerProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const BuildingDashboard: React.FC<BuildingDashboardProps> = (props) => {
  const { ...wrapperProps } = props;
  const { ROOT, nrrvre, arufhq, jagbhd, ebaqji, mcghoj } = defaultProps;

  return (
    <Container {...ROOT} {...wrapperProps}>
      <Container {...mcghoj}>
        <Image {...nrrvre} />
        <Container {...jagbhd}>
          <Typography {...arufhq} />
          <Typography {...ebaqji} />
        </Container>
      </Container>
    </Container>
  );
};

export default BuildingDashboard;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: { textAlign: "center" },
  },
  nrrvre: {
    alt: "Image description for better SEO",
    src: "/images/building.svg",
    dynamic: true,
    css: { maxHeight: "300px" },
  },
  arufhq: {
    variant: "h2",
    text: "Préparez-vous : Votre tableau de bord est en cours de construction !",
    gutterBottom: false,
    noWrap: false,
    css: {
      fontSize: "24px",
      marginTop: "16px",
      fontWeight: 600,
      marginBottom: "8px",
    },
  },
  jagbhd: { maxWidth: "sm", disableGutters: false, fixed: false },
  ebaqji: {
    variant: "body1",
    text: "Nous mettons les dernières touches pour vous offrir une expérience \nexceptionnelle. Restez à l'écoute pour découvrir votre tout nouveau \ntableau de bord !",
    gutterBottom: false,
    noWrap: false,
    css: { color: "#5C5C60" },
  },
  mcghoj: {
    maxWidth: "md",
    disableGutters: false,
    fixed: false,
    css: { padding: "16px", borderRadius: "22px" },
  },
};

/** DEFAULT PROPS End */
