/** Generated automaticaly by repleek */
/** IMPORT Start */
import React from "react";

import {
  Autocomplete,
  AutocompleteProps,
  Container,
  ContainerProps,
} from "@repleek/mui";

/** IMPORT End */

/** TYPE DEFINITION Start */
type AccountFilterFormProps = {
  type: AutocompleteProps,
};

type DefaultProps = {
  ROOT: ContainerProps,
  ftyatx: AutocompleteProps,
};

/** TYPE DEFINITION End */

/** COMPONENT Start */
const AccountFilterForm: React.FC<AccountFilterFormProps> = (props) => {
  const { type } = props;
  const { ROOT, ftyatx } = defaultProps;

  return (
    <Container {...ROOT}>
      <Autocomplete {...ftyatx} {...type} />
    </Container>
  );
};

export default AccountFilterForm;

/** COMPONENT End */

/** DEFAULT PROPS Start */
const defaultProps: DefaultProps = {
  ROOT: {
    maxWidth: "xs",
    disableGutters: false,
    fixed: false,
    css: { paddingTop: "16px", paddingBottom: "8px" },
  },
  ftyatx: {
    inputName: "type",
    label: "Type de compte",
    variant: "outlined",
    color: "secondary",
    "aria-label": "Input Base Mui",
    clearText: "Vider",
    closeText: "Fermer",
    loadingText: "Chargement ...",
    noOptionsText: "No option",
    disabled: false,
    required: false,
    autoComplete: false,
    autoHighlight: false,
    autoSelect: false,
    blurOnSelect: false,
    clearOnBlur: false,
    clearOnEscape: false,
    fullWidth: true,
    disableClearable: false,
    disableCloseOnSelect: false,
    disabledItemsFocusable: false,
    disableListWrap: false,
    disablePortal: false,
    filterSelectedOptions: true,
    freeSolo: false,
    multiple: true,
    chipsColor: "default",
    chipsSize: "medium",
    chipsVariant: "filled",
    css: { minWidth: "340px" },
  },
};

/** DEFAULT PROPS End */
